<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row>
      <i-col>
        <pmis-content-box no-border-box>
          <template #title-right>
            <iui-button :enable="isCanPrint" v-show="isCanPrint" @click="onControlButton('print')"
              >서면(용)출력</iui-button
            >
            <iui-button :enable="isCanOnOffManage" v-show="isCanOnOffManage" @click="onControlButton('onOffManage')"
              >확인서(서면/전자)관리</iui-button
            >
            <iui-button :enable="isCanReqSign" v-show="isCanReqSign" @click="onControlButton('reqSign')"
              >전자서명요청</iui-button
            >
            <iui-button :enable="isCanRecovery" v-show="isCanRecovery" @click="onControlButton('recovery')"
              >회수</iui-button
            >
            <iui-button :enable="isCanSendAttach" v-show="isCanSendAttach" @click="onControlButton('sendAttach')"
              >작업증빙사진 송부(카톡/메일)</iui-button
            >
          </template>
          <iui-container type="jqgrid" :id="sheetIdSub" :loading="listBoxLoading" style="width:99.9%" />
          <iui-modal-old
            :name="onofflineModalId"
            :title="'확인서(서면/전자)관리'"
            :btns="modalBtns1"
            width="800px"
            height="250px"
          >
          <Onoffline />
          </iui-modal-old>
          <iui-modal-old
            :name="checkeSameListModalId"
            :title="'확인자 중복 목록'"
            :btns="modalBtns2"
            width="800px"
            height="370px"
          >
            <CheckeSameList />
          </iui-modal-old>
        </pmis-content-box>
        <div style="font-size:12px;margin-top:3px;position: fixed;bottom: 4px;">
          <!--20210908 lgksja position: fixed;
    bottom: 5px; 추가-->
          * 호기별 고객 확인자가 같을 경우 선택 후 붙여넣기 기능을 사용해주세요.
        </div>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import Onoffline from '../../components/popup/Onoffline.vue';
import CheckeSameList from '../../components/popup/CheckeSameList.vue';
export default {
  name: 'part-list',
  props: {
    otisPage: {
      // OSE 페이지
      type: Boolean,
      default: false,
    },
    customerPage: {
      // 업체 페이지
      type: Boolean,
      default: false,
    },
  },
  components: {
    Onoffline,
    CheckeSameList,
  },
  beforeCreate() {
    $mapGetters(this, ['cnfrmnMain', 'tradeType', 'isChargeEmp', 'mapSubList']);
    $mapMutations(this, ['setModalOnoffline', 'setMapSubList','setModalCheckeSameList']);
  },
  created() {
    this.addEvent({name: 'PartSubList_PreSearch', func: this.onPreSearch});
    this.addEvent({name: 'PartSubList_Search', func: this.onSearch});
    this.addEvent({name: 'PartSubList_ReBindJqGrid', func: this.onReBindJqGrid});
    this.addEvent({name: 'PartSubList_ResetSubListData', func: this.onResetSubListData});

    this.addEvent({name: 'CheckeSameListManage_Popup', func: this.checkeSameListManage});
  },
  data() {
    return {
      //sheetIdSub: 'sheet',// + this._uid,
      isCustAlarmNot : false,
      sheetIdSub: 'sheetSub' + this.$store.getters['mdi/currentUrls'].menuId,

      onofflineModalId: 'onofflineModal' + this._uid,

      modalBtns1: [
        {name: '완료처리', callback: this.onOnofflineComplete},
        {name: '임시저장', callback: this.onOnofflineSave},
      ],

      checkeSameListModalId: 'checkeSameListModal' + this._uid,

      modalBtns2: [
        {name: '저장', callback: this.onCheckerSave},
        
      ],

      modalNm: '',

      search: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        rqestNo: '',
        cnfrmnNo: '',
        isOtisPage: this.otisPage,
        isCustomerPage: this.customerPage,
        paymentType: '',
        statusCode: '',
        summaryYm: '',
        empNo: '',
      },

      listBoxLoading: false,

      focusKeys: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        rqestNo: '',
        cnfrmnNo: '',
        cnfrmnSttusCd: '',
      },

      // eslint-disable-next-line
      chkcell0: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      // eslint-disable-next-line
      chkcell1: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      // eslint-disable-next-line
      chkcell2: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      // eslint-disable-next-line
      chkcell3: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      // eslint-disable-next-line
      chkcell4: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      // eslint-disable-next-line
      chkcell5: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      // eslint-disable-next-line
      chkcell6: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크

      chkcell7: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      chkcell8: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      chkcell9: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      chkcell10: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      chkcell11: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      chkcell12: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
      chkcell13: {cellId: undefined, chkval: undefined, grRequestNo: undefined}, //cell rowspan 중복 체크
    };
  },
  mounted() {
    this.onReBindJqGrid();

    const me = this;
    var $grid = $('#' + me.sheetIdSub);
    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);

    this.onResetSubListData();
  },
  methods: {
    isCustAlarmNotCheck(flag) {
      this.isCustAlarmNot = flag;
    },
    async onReBindJqGrid() {
      // console.log('SubList onReBindJqGrid Start');

      const OPT = {
        Cols: [
          {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
          {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
          {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
          {name: 'cnfrmnNo', label: 'cnfrmnNo', width: 0, hidden: true},
          {name: 'fileName', label: 'fileName', width: 0, hidden: true},
          {name: 'fileNo', label: 'fileNo', width: 0, hidden: true},
          {name: 'regPhotoYn', label: 'regPhotoYn', width: 0, hidden: true},
          {name: 'isDocBilled', label: 'isDocBilled', width: 0, hidden: true},

          //서명요청시 거래선번호가 있는지 확인을 위한 20241029 lgksja
          {name: 'tradeNo', label: 'tradeNo', width: 0, hidden: true},
          {name: 'minMainName', label: 'minMainName', width: 0, hidden: true},
        ],
      };

      // console.log('SubList this.tradeType', this.tradeType);
      // console.log('SubList compare ($isAdmin() || this.isChargeEmp)', ($isAdmin() || this.isChargeEmp));
      // console.log('SubList compare tradeType', (this.tradeType == 'OTIS' || this.tradeType == 'CCPY'));

      if ($isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY') {
        OPT.Cols.push({
          name: 'chk',
          label: '선택',
          width: 40,
          align: 'center',
          formatter: function(cellvalue) {
            return (
              '<input type="checkBox" name="chkCnfrmnNo" value="' +
              cellvalue +
              '" style="display:block !important; margin: 0px auto;">'
            );
          },
          cellattr:
            // eslint-disable-next-line
            function(rowid, val, rowObject, cm, rdata) {
              var result = '';
              if (rdata.cnfrmnNo.indexOf('#') > -1) {
                // console.log('me.chkcell0.chkval', me.chkcell0.chkval);
                // console.log('val', val);
                if (me.chkcell0.chkval != val) {
                  //check 값이랑 비교값이 다른 경우
                  var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                  result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                  me.chkcell0 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                } else {
                  result += 'style="display:none"  rowspanid="' + me.chkcell0.cellId + '"'; //같을 경우 display none 처리
                }
              }
              return result;
            },
        });
      }
      OPT.Cols.push({
        name: 'grRequestNo',
        label: '그룹번호',
        width: 120,
        align: 'center',
        sortable: false,
        cellattr:
          // eslint-disable-next-line
          function(rowid, val, rowObject, cm, rdata) {
            var result = '';
            if (me.chkcell1.chkval != val) {
              //check 값이랑 비교값이 다른 경우
              var cellId = this.id + '_row_' + rowid + '-' + cm.name;
              result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
              me.chkcell1 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
            } else {
              result += 'style="display:none"  rowspanid="' + me.chkcell1.cellId + '"'; //같을 경우 display none 처리
            }
            return result;
          },
      });
      OPT.Cols.push({name: 'rqestNo', label: '청구번호', width: 120, align: 'center', cellattr: $_rowMearge});

      OPT.Cols.push({name: 'paymentType', label: '유/무상', width: 70, align: 'center', cellattr: $_rowMearge});
      OPT.Cols.push({name: 'saCode', label: '사유', width: 0, hidden: true});
      OPT.Cols.push({name: 'saName', label: '사유', width: 100, align: 'center', cellattr: $_rowMearge});
      OPT.Cols.push({
        name: 'rqestDate',
        label: '청구일',
        width: 75,
        align: 'center',
        sorttype: 'date',
        formatter: 'date',
        formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        cellattr: $_rowMearge,
      });
      OPT.Cols.push({name: 'rqestChrgNm', label: '청구담당', width: 80, align: 'center', cellattr: $_rowMearge});
      /*
      OPT.Cols.push(
        { name: "signDt",                 label: "서명일<br/>(고객날인일)",              width: 75,    align: "center", sorttype: "date", formatter: "date", formatoptions: { userLocalTime : false, srcformat: "ISO8601Long", newformat: "Y-m-d" } , cellattr: $_rowMearge },
      );
      */
      // OPT.Cols.push(
      //   { name: "paymentType",            label: "유/무상",             width: 70,    align: "center", cellattr:
      //       // eslint-disable-next-line
      //     function(rowid, val, rowObject, cm, rdata) {
      //       var result = "";
      //       if (rdata.cnfrmnNo.indexOf("#") > -1) {
      //         if (/* me.chkcell4.chkval != val*/ me.chkcell7.grRequestNo !=rdata.cnfrmnNo) { //check 값이랑 비교값이 다른 경우
      //             var cellId = this.id + '_row_'+rowid+'-'+cm.name;
      //             result += ' rowspan="1" id ="'+cellId+'" name="cellRowspan"';
      //             me.chkcell7 = {cellId:cellId, chkval:val, grRequestNo:rdata.cnfrmnNo};
      //         } else {
      //             result += 'style="display:none"  rowspanid="'+me.chkcell7.cellId+'"'; //같을 경우 display none 처리
      //         }
      //       }
      //       return result;
      //     }
      //   },
      // );
      // OPT.Cols.push(
      //   { name: "rqestDate",              label: "청구일",              width: 75,    align: "center", sorttype: "date", formatter: "date", formatoptions: { userLocalTime : false, srcformat: "ISO8601Long", newformat: "Y-m-d" } , cellattr:
      //       // eslint-disable-next-line
      //     function(rowid, val, rowObject, cm, rdata) {
      //       var result = "";
      //       if (rdata.cnfrmnNo.indexOf("#") > -1) {
      //         if (/* me.chkcell4.chkval != val*/ me.chkcell8.grRequestNo !=rdata.cnfrmnNo) { //check 값이랑 비교값이 다른 경우
      //             var cellId = this.id + '_row_'+rowid+'-'+cm.name;
      //             result += ' rowspan="1" id ="'+cellId+'" name="cellRowspan"';
      //             me.chkcell8 = {cellId:cellId, chkval:val, grRequestNo:rdata.cnfrmnNo};
      //         } else {
      //             result += 'style="display:none"  rowspanid="'+me.chkcell8.cellId+'"'; //같을 경우 display none 처리
      //         }
      //       }
      //       return result;
      //     }
      //   },
      // );
      // OPT.Cols.push(
      //   { name: "rqestChrgNm",            label: "청구담당",            width: 80,    align: "center", cellattr:
      //       // eslint-disable-next-line
      //     function(rowid, val, rowObject, cm, rdata) {
      //       var result = "";
      //       if (rdata.cnfrmnNo.indexOf("#") > -1) {
      //         if (/* me.chkcell4.chkval != val*/ me.chkcell9.grRequestNo !=rdata.cnfrmnNo) { //check 값이랑 비교값이 다른 경우
      //             var cellId = this.id + '_row_'+rowid+'-'+cm.name;
      //             result += ' rowspan="1" id ="'+cellId+'" name="cellRowspan"';
      //             me.chkcell9 = {cellId:cellId, chkval:val, grRequestNo:rdata.cnfrmnNo};
      //         } else {
      //             result += 'style="display:none"  rowspanid="'+me.chkcell9.cellId+'"'; //같을 경우 display none 처리
      //         }
      //       }
      //       return result;
      //     }
      //   },
      // );
      OPT.Cols.push({
        name: 'signDt',
        label: '서명일<br/>(고객날인일)',
        width: 75,
        align: 'center',
        sorttype: 'date',
        formatter: 'date',
        formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
        cellattr:
          // eslint-disable-next-line
          function(rowid, val, rowObject, cm, rdata) {
            var result = '';
            if (rdata.cnfrmnNo.indexOf('#') > -1) {
              if (/* me.chkcell4.chkval != val*/ me.chkcell10.grRequestNo != rdata.cnfrmnNo) {
                //check 값이랑 비교값이 다른 경우
                var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                me.chkcell10 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
              } else {
                result += 'style="display:none"  rowspanid="' + me.chkcell10.cellId + '"'; //같을 경우 display none 처리
              }
            }
            return result;
          },
      });
      OPT.Cols.push({name: 'cnfrmnSttusCd', label: '상태', width: 0, hidden: true});
      OPT.Cols.push({
        name: 'cnfrmnSttusNm',
        label: '상태',
        width: 70,
        align: 'center',
        formatter: function(cellvalue, options, rowObject) {
          //20210909 lgksja formatter 추가
          var returnStr = cellvalue;
          if (rowObject.cnfrmnSttusCd == '50' && rowObject.isDocBilled == 'Y') {
            //완료 이고 세금계산서 발행이면
            returnStr = 'TAX';
          }
          return returnStr;
        },
        cellattr:
          // eslint-disable-next-line
          function(rowid, val, rowObject, cm, rdata) {
            var result = '';
            if (rdata.cnfrmnNo.indexOf('#') > -1) {
              if (/*me.chkcell2.chkval != val &&*/ me.chkcell2.grRequestNo != rdata.cnfrmnNo) {
                //check 값이랑 비교값이 다른 경우
                var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                me.chkcell2 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
              } else {
                result += 'style="display:none"  rowspanid="' + me.chkcell2.cellId + '"'; //같을 경우 display none 처리
              }
            }
            return result;
          },
      });
      OPT.Cols.push({name: 'onOffDoc', label: '확인서', width: 0, hidden: true});
      OPT.Cols.push(
        // eslint-disable-next-line
        {
          name: 'onOffDocNm',
          label: '확인서',
          width: 60,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            /*20210909 lgksja N/A 추가 ~S*/
            //return (cellvalue == 'ON' ? '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>' : '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>');
            var returnStr =
              cellvalue == 'ON'
                ? '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>'
                : '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>';
            //20211208 완료일때 조건 삭제
            if (
              /*rowObject.cnfrmnSttusCd == '50'&& */ rowObject.onOffDocNm !== 'ON' &&
              (rowObject.fileNo == '0' || rowObject.fileName == null)
            ) {
              returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">N/A</button>';
            } else if (
              rowObject.onOffDocNm == 'ON' &&
              rowObject.cnfrmnSttusCd == '50' &&
              rowObject.fileNo == '0' &&
              rowObject.signDt == null
            ) {
              //20211215 엑셀 99번
              returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">N/A</button>';
            }
            return returnStr;
            /*20210909 lgksja N/A 추가 ~S*/
          },
          cellattr:
            // eslint-disable-next-line
            function(rowid, val, rowObject, cm, rdata) {
              var result = '';
              if (rdata.cnfrmnNo.indexOf('#') > -1) {
                if (/*me.chkcell3.chkval != val*/ me.chkcell3.grRequestNo != rdata.cnfrmnNo) {
                  //check 값이랑 비교값이 다른 경우
                  var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                  result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                  me.chkcell3 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                } else {
                  result += 'style="display:none"  rowspanid="' + me.chkcell3.cellId + '"'; //같을 경우 display none 처리
                }
              }
              return result;
            },
        }
      );
      if ($isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY') {
        OPT.Cols.push({name: 'regYn', label: '입력여부', width: 0, hidden: true});
        OPT.Cols.push({
          name: 'regYnNm',
          label: '입력여부',
          width: 80,
          align: 'center',
          cellattr:
            // eslint-disable-next-line
            function(rowid, val, rowObject, cm, rdata) {
              var result = '';
              if (rdata.cnfrmnNo.indexOf('#') > -1) {
                if (/* me.chkcell4.chkval != val*/ me.chkcell4.grRequestNo != rdata.cnfrmnNo) {
                  //check 값이랑 비교값이 다른 경우
                  var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                  result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                  me.chkcell4 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                } else {
                  result += 'style="display:none"  rowspanid="' + me.chkcell4.cellId + '"'; //같을 경우 display none 처리
                }
              }
              return result;
            },
        });
        OPT.Cols.push(
          // eslint-disable-next-line
          {
            name: 'sel',
            label: '선택',
            width: 40,
            align: 'center',
            editable: false,
            edittype: 'radio',
            sortable: false,
            classes: 'pss-jqgrid-pointer-nodecoration',
            // eslint-disable-next-line
            formatter: function(cellvalue, options) {
              return (
                '<input type="radio" name="sel" value="' +
                cellvalue +
                '" style="display:block !important; margin: 0px auto;">'
              );
            },
            cellattr:
              // eslint-disable-next-line
              function(rowid, val, rowObject, cm, rdata) {
                var result = '';
                if (rdata.cnfrmnNo.indexOf('#') > -1) {
                  if (/*me.chkcell5.chkval != val*/ me.chkcell5.grRequestNo != rdata.cnfrmnNo) {
                    //check 값이랑 비교값이 다른 경우
                    var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                    result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                    me.chkcell5 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                  } else {
                    result += 'style="display:none"  rowspanid="' + me.chkcell5.cellId + '"'; //같을 경우 display none 처리
                  }
                }
                return result;
              },
          }
        );
        OPT.Cols.push(
          // eslint-disable-next-line
          {
            name: 'paste',
            label: '기능',
            width: 100,
            align: 'center',
            // eslint-disable-next-line
            formatter: function(cellvalue, options, rowObject) {
              return cellvalue == 'true' ? '<button>붙여넣기</button>' : '';
            },
            cellattr:
              // eslint-disable-next-line
              function(rowid, val, rowObject, cm, rdata) {
                var result = '';
                if (rdata.cnfrmnNo.indexOf('#') > -1) {
                  if (/*me.chkcell6.chkval != val*/ me.chkcell6.grRequestNo != rdata.cnfrmnNo) {
                    //check 값이랑 비교값이 다른 경우
                    var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                    result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                    me.chkcell6 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                  } else {
                    result += 'style="display:none"  rowspanid="' + me.chkcell6.cellId + '"'; //같을 경우 display none 처리
                  }
                }
                return result;
              },
          }
        );
        /* 20210903 lgksja ~ s*/
        OPT.Cols.push(
          {
            name: 'sendngDt',
            label: '서명요청일<br/>(서면확정일)',
            width: 80,
            align: 'center',
            cellattr:
              // eslint-disable-next-line
              function(rowid, val, rowObject, cm, rdata) {
                var result = '';
                if (rdata.cnfrmnNo.indexOf('#') > -1) {
                  if (/* me.chkcell4.chkval != val*/ me.chkcell11.grRequestNo != rdata.cnfrmnNo) {
                    //check 값이랑 비교값이 다른 경우
                    var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                    result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                    me.chkcell11 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                  } else {
                    result += 'style="display:none"  rowspanid="' + me.chkcell11.cellId + '"'; //같을 경우 display none 처리
                  }
                }
                return result;
              },
          },
          {
            name: 'sendngEmplId',
            label: '서명요청자',
            width: 60,
            align: 'center',
            cellattr:
              // eslint-disable-next-line
              function(rowid, val, rowObject, cm, rdata) {
                var result = '';
                if (rdata.cnfrmnNo.indexOf('#') > -1) {
                  if (/* me.chkcell4.chkval != val*/ me.chkcell12.grRequestNo != rdata.cnfrmnNo) {
                    //check 값이랑 비교값이 다른 경우
                    var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                    result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                    me.chkcell12 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                  } else {
                    result += 'style="display:none"  rowspanid="' + me.chkcell12.cellId + '"'; //같을 경우 display none 처리
                  }
                }
                return result;
              },
          }
        );
        OPT.Cols.push({
          name: 'slngEmplId',
          label: '서명자<br/>(확정자)',
          width: 60,
          align: 'center',
          cellattr:
            // eslint-disable-next-line
            function(rowid, val, rowObject, cm, rdata) {
              var result = '';
              if (rdata.cnfrmnNo.indexOf('#') > -1) {
                if (/* me.chkcell4.chkval != val*/ me.chkcell13.grRequestNo != rdata.cnfrmnNo) {
                  //check 값이랑 비교값이 다른 경우
                  var cellId = this.id + '_row_' + rowid + '-' + cm.name;
                  result += ' rowspan="1" id ="' + cellId + '" name="cellRowspan"';
                  me.chkcell13 = {cellId: cellId, chkval: val, grRequestNo: rdata.cnfrmnNo};
                } else {
                  result += 'style="display:none"  rowspanid="' + me.chkcell13.cellId + '"'; //같을 경우 display none 처리
                }
              }
              return result;
            },
        });
        /* 20210903 lgksja ~ e*/
      }

      const me = this;
      var $grid = $('#' + me.sheetIdSub);
      $grid.jqGrid('GridUnload');
      $grid
        .jqGrid({
          height: 120,
          colModel: OPT.Cols,
          selectionMode: 'singlerow',
          autowidth: true,
          shrinkToFit: true,
          // eslint-disable-next-line
          onCellSelect: function(rowId, iCol, cellContent, e) {
            var rowData = $('#' + me.sheetIdSub).jqGrid('getRowData', rowId);
            me.setFocusKeys(rowData);

            if (iCol == $('#' + me.sheetIdSub).jqGrid('getGridParam').iColByName['chk']) {
              return false;
            } else if (iCol == $('#' + me.sheetIdSub).jqGrid('getGridParam').iColByName['onOffDocNm']) {
              console.log('rowData', rowData);
              if (rowData.cnfrmnSttusCd == '50' && rowData.fileNo == '0') {
                //파일이 없다면 아무 것도 안함
              } else {
                if (rowData.onOffDoc == 'ON') {
                  if (rowData.cnfrmnSttusCd == '50') {
                    //20211110 lgksja 86번
                    me.onDownloadOfflineConfDoc(rowData);
                  } else {
                    me.onView(rowData);
                  }
                } else {
                  if (rowData.fileName !== '' && rowData.fileName != null) {
                    //20211208조건 추가
                    me.onDownloadOfflineConfDoc(rowData);
                  }
                }
              }
              return false;
            } else if (iCol == $('#' + me.sheetIdSub).jqGrid('getGridParam').iColByName['sel']) {
              var $radio = $(e.target)
                .closest('tr')
                .find('input[type="radio"]');
              $radio.attr('checked', rowData.regYn !== undefined && rowData.regYn == 'Y' ? true : false);
              return false;
            } else if (iCol == $('#' + me.sheetIdSub).jqGrid('getGridParam').iColByName['paste']) {
              if (rowData.paste.indexOf('붙여넣기') > -1) {
                if (me.tradeType != 'CUST') {
                  var selCnfrmnNo = '';
                  var objs = document.getElementsByName('sel');
                  // console.log("objs = "+objs);
                  var sz = objs.length;
                  if (sz < 1) return;
                  for (var i = 0; i < sz; i++) {
                    if (objs[i].checked) {
                      selCnfrmnNo = objs[i].value;
                      break;
                    }
                  }
                  if (selCnfrmnNo == '') {
                    me.$alert('붙여넣을 정보를 가져올 행의 고객정보를 선택 해 주세요.');
                    return;
                  } else {
                    if (!(rowData.cnfrmnSttusCd == '10' || rowData.cnfrmnSttusCd == '30')) {
                      me.$alert('작성중 또는 회수 상태에서만 붙여넣기를 할 수 있습니다.');
                      return;
                    }

                    me.onCheckerPaste(rowData, selCnfrmnNo);
                  }
                  return false;
                }
              }
            }
            me.$emit('selRow', rowData);
            return true;
          },
          gridComplete: function() {
            /** 데이터 로딩시 함수 **/
            // console.log('gridComplete');
            me.resetChkcell();
            var grid = this;

            $('td[name="cellRowspan"]', grid).each(function() {
              var spans = $('td[rowspanid="' + this.id + '"]', grid).length + 1;
              if (spans > 1) {
                $(this).attr('rowspan', spans);
              }
            });
          },
        })
        .setGridWidth($('#' + this.sheetIdSub).width() * 1.3); /*20211110 lgksja setGridWidth 추가*/

      if ($isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY') {
        var groups = [];
        groups.push({startColumnName: 'regYnNm', numberOfColumns: 3, titleText: '고객정보'});

        $grid.jqGrid('setGroupHeaders', {
          useColSpanStyle: true,
          groupHeaders: groups,
        });
      }

      // $gridAutoResize( $grid );
      // window.addEventListener('resize', this.onResize);
      setTimeout(() => {
        this.onResize();
        //20210908 lgksja 스크롤바가 생겨서 꽁수로 그리드 한번더 조정
        //setTimeout(() => {
        //  this.onResize();
        //}, 1000);
      }, 1000);

      // console.log('this.chkcell0.chkval', this.chkcell0.chkval);

      // console.log('SubList onReBindJqGrid End');
    },
    onResetSubListData() {
      $('#' + this.sheetIdSub).clearGridData(true);
    },
    setFocusKeys(rowData) {
      this.focusKeys.ctrtNo = rowData.ctrtNo;
      this.focusKeys.ctrtOdr = rowData.ctrtOdr;
      this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
      this.focusKeys.rqestNo = rowData.rqestNo;
      this.focusKeys.cnfrmnNo = rowData.cnfrmnNo;
      this.focusKeys.cnfrmnSttusCd = rowData.cnfrmnSttusCd;
    },
    // eslint-disable-next-line
    onResize(event) {
      //console.log('window has been resized', event);
      var $grid = $('#' + this.sheetIdSub);
      var pobj = $grid.closest('.ui-jqgrid').parent();
      $grid.jqGrid().setGridWidth(pobj.width() * 1.3);

      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 1, false);

      var bottomLeftObj = $grid
        .closest('.bullet')
        .parent()
        .parent()
        .parent();
      /*20210907 lgksja ~S*/
      //$grid.jqGrid("setGridHeight", bottomLeftObj.height()-100, false);
      console.log('bottomLeftObj.height()-90', bottomLeftObj.height() - 100);
      $grid.jqGrid('setGridHeight', bottomLeftObj.height() - 100, false);
      /*20210907 lgksja ~E*/
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    onPreSearch(param) {
      this.focusKeys.ctrtNo = '';
      this.focusKeys.ctrtOdr = '';
      this.focusKeys.ctrtDivCd = '';
      this.focusKeys.rqestNo = '';
      this.focusKeys.cnfrmnNo = '';
      this.focusKeys.cnfrmnSttusCd = '';

      if (param !== undefined) {
        this.focusKeys.ctrtNo = param.ctrtNo;
        this.focusKeys.ctrtOdr = param.ctrtOdr;
        this.focusKeys.ctrtDivCd = param.ctrtDivCd;

        this.search.paymentType = param.paymentType;
        this.search.statusCode = param.statusCode;
        this.search.summaryYm = param.summaryYm;
        this.search.empNo = param.empNo;
      }

      this.onSearch();
    },
    resetChkcell() {
      // console.log('window has been resized', event);
      this.chkcell0 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      // console.log('window has been resized', event);
      this.chkcell1 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      // console.log('window has been resized', event);
      this.chkcell2 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      // console.log('window has been resized', event);
      this.chkcell3 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      // console.log('window has been resized', event);
      this.chkcell4 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      // console.log('window has been resized', event);
      this.chkcell5 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      // console.log('window has been resized', event);
      this.chkcell6 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};

      this.chkcell7 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      this.chkcell8 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      this.chkcell9 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      this.chkcell10 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      this.chkcell11 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      this.chkcell12 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
      this.chkcell13 = {cellId: undefined, chkval: undefined, grRequestNo: undefined};
    },
    async onSearch() {
      this.resetChkcell();

      this.listBoxLoading = true;

      let param = {
        ctrtNo: this.focusKeys.ctrtNo,
        ctrtOdr: this.focusKeys.ctrtOdr,
        ctrtDivCd: this.focusKeys.ctrtDivCd,
        otisPage: this.otisPage,
        customerPage: this.customerPage,
        paymentType: this.search.paymentType,
        statusCode: this.search.statusCode,
        summaryYm: this.search.summaryYm,
        empNo: this.search.empNo,
      };

      let response = null;
      try {
        response = await axios.post('/confdoc/part/selectSubList', param);
        this.listBoxLoading = false;

        // console.log('onSearch');
        // console.log('param.ctrtNo', param.ctrtNo);
        // console.log('response', response);

        const map = {...this.mapSubList}; // getters의 mapSubList을 가져온후
        map[param.ctrtNo] = response; // 새로운 key로 mapSubList을 재설정
        this.setMapSubList(map); // mutations을 통하여 store에 저장

        // 2021.08.30
        // 조회한 SubList를 바로 Grid에 바인딩 하지 않고 store의 mapSubList에 저장하면 watch로 mapSubList를 모니터링해서 bindGrid를 호출함.
        // 이렇게 하는 이유는 MainList의 특정 행을 연속으로 여러개 클릭하면 마지막으로 MainList의 선택된 행의 데이터가 아니라
        // 마지막에 가져오는 SubList의 내용으로 출력되어 데이터를 가져오는 로직과 바인딩 하는 로직을 분리함.

        // $("#"+this.sheetIdSub).clearGridData(true).setGridParam({data:response.data}).trigger("reloadGrid");

        // var rowData;
        // var ids = $("#"+this.sheetIdSub).jqGrid("getDataIDs");
        // if (ids && ids.length > 0) {
        //   if (this.focusKeys.cnfrmnNo) {
        //     for (var i = 0; i <= ids.length-1; i++) {
        //       rowData = $("#"+this.sheetIdSub).jqGrid('getRowData', ids[i]);

        //       if (rowData["cnfrmnNo"] == this.focusKeys.cnfrmnNo) {
        //         // console.log("SubList 일치하는 Row 찾아 조회");
        //         $("#"+this.sheetIdSub).jqGrid('setSelection', ids[i], true);
        //         this.$emit('selRow', rowData);
        //         this.setFocusKeys(rowData);
        //         break;
        //       }
        //     }
        //   } else {
        //     // console.log("SubList 일치하는 Row 못찾아 첫번째 행을 조회");
        //     $("#"+this.sheetIdSub).jqGrid('setSelection', ids[0], true);
        //     rowData = $("#"+this.sheetIdSub).jqGrid("getRowData", ids[0]);
        //     this.$emit('selRow', rowData);
        //     this.setFocusKeys(rowData);
        //   }
        // } else {
        //   // console.log("SubList 검색된 데이터 없어서 리셋");
        //   this.$emit('resetValues', null);
        // }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    bindGrid() {
      // console.log('bindGrid');
      // console.log('this.focusKeys.ctrtNo', this.focusKeys.ctrtNo);
      // console.log('this.mapSubList', this.mapSubList);
      var response = this.mapSubList[this.focusKeys.ctrtNo];
      // console.log('response', response);

      if (response !== undefined && response != null && response.data != null) {
        $('#' + this.sheetIdSub)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        var rowData;
        var ids = $('#' + this.sheetIdSub).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.cnfrmnNo) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetIdSub).jqGrid('getRowData', ids[i]);

              if (rowData['cnfrmnNo'] == this.focusKeys.cnfrmnNo) {
                // console.log("SubList 일치하는 Row 찾아 조회");
                $('#' + this.sheetIdSub).jqGrid('setSelection', ids[i], true);
                this.$emit('selRow', rowData);
                this.setFocusKeys(rowData);
                break;
              }
            }
          } else {
            // console.log("SubList 일치하는 Row 못찾아 첫번째 행을 조회");
            $('#' + this.sheetIdSub).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetIdSub).jqGrid('getRowData', ids[0]);
            this.$emit('selRow', rowData);
            this.setFocusKeys(rowData);
          }
        } else {
          // console.log("SubList 검색된 데이터 없어서 리셋");
          this.$emit('resetValues', null);
        }
      }
    },
    onOpenPopup(modalNm) {
      this.modalNm = modalNm;
      this.$modal.show(modalNm);
    },
    onOnofflineComplete() {
      this.callEvent({
        name: 'Onoffline_Complete',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.onSearch();
        },
      });
    },
    onOnofflineSave() {
      this.callEvent({
        name: 'Onoffline_Save',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.onSearch();
        },
      });
    },
    onOnofflineDeleteFile() {
      //20211208 파일삭제용 추가
      this.callEvent({
        name: 'Onoffline_DeleteFile',
        param: data => {
          console.log(data);
          //this.$modal.hide(this.modalNm);
          this.onSearch();
        },
      });
    },
    onCheckerSave() {
      console.log("aaaaaaaaaaaaaaaaaaaaaa");
      console.log(" this.modalNm2", this.modalNm);
      this.callEvent({
        name: 'modal_Checker_Save',
        param: data => {
          console.log(data);
         
          this.$modal.hide(this.modalNm);
          //this.onSearch();
        },
      });
    },

    async onControlButton(type) {
      const me = this;

      console.log('ctrtNo', me.cnfrmnMain.ctrtNo);
      console.log('ctrtOdr', me.cnfrmnMain.ctrtOdr);
      console.log('ctrtDivCd', me.cnfrmnMain.ctrtDivCd);
      console.log('rqestNo', me.cnfrmnMain.rqestNo);
      console.log('cnfrmnNo', me.cnfrmnMain.cnfrmnNo);

      if (type == 'print') {
        me.onPrint();
      } else if (type == 'onOffManage') {
        me.onOnOffManage();
      } else if (type == 'reqSign') {
        me.onReqSign();
      } else if (type == 'recovery') {
        me.onRecovery();
      } else if (type == 'sendAttach') {
        me.onSendAttach();
      }
    },
    async onPrint() {
      //리포트 정보 조회 후 오즈뷰어 팝업시킴.
      let param = {
        cnfrmnNo: this.focusKeys.cnfrmnNo,
      };

      const btns = [];

      console.log('btns', btns);

      let blinkCnfrmn = false;
      if (await this.$confirm('확인자/서명자 정보를 공란으로 출력하시겠습니까?')) {
        blinkCnfrmn = true;
      }

      axios
        .post('/ozrpt/selectOzCntrwkFileName', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          this.OZParam.OZFormParam = {
            signable: 'N', //필수(*):서명가능여부(Y/N)
            signMthCd: '30',
            onlineYn: 'N',
            cnfrmnNo: response.data.cnfrmnNo, //확인서번호
            cntrwkNo: response.data.cntrwkNo, //계약서번호
            cntrwkOdr: response.data.cntrwkOdr, //계약차수
            blinkCnfrmn: blinkCnfrmn, // 확인자 공란 여부
            userId: this.otisPage ? this.userInfo.empNo : this.customerPage ? this.userInfo.tradeNo : '',
          };
          /*
        {
            "cnfrmnNo": "R2021064957",
            "OZParam": {
                "reportname": "/confirmation/DircPartChange.ozr",
                "odiname": "DircPartChange",
                "OZFormParam": {
                    "signable": "N",
                    "signMthCd": "30",
                    "onlineYn": "N",
                    "cnfrmnNo": "R2021064957",
                    "cntrwkNo": "",
                    "cntrwkOdr": "",
                    "userId": "T00480"
                }
            },
            "otisPage": true
        }
        */
          $open({
            componentPath: '@/view/confdoc/part/components/popup/ConfDocPartPrint.vue', // 컴포넌트 경로 (필수)
            param: {
              cnfrmnNo: this.focusKeys.cnfrmnNo,
              OZParam: this.OZParam,
              otisPage: this.otisPage,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'ConfDocPartPrint', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: (response.data.saCode == 'A' ? '부품교체확인서출력' : '부품판매확인서출력'), // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    async onOnOffManage() {
      let modalOnoffline = {};
      modalOnoffline.cnfrmnNo = this.focusKeys.cnfrmnNo;
      modalOnoffline.cnfrmnSttusCd = this.focusKeys.cnfrmnSttusCd;
      modalOnoffline.readonly = this.focusKeys.cnfrmnSttusCd == '50' ? true : false;
      modalOnoffline.onofflineModalId = this.onofflineModalId; //20211208 파일삭제 백그라운드 리로드
      this.setModalOnoffline(modalOnoffline);

      if (this.focusKeys.cnfrmnSttusCd == '20' || this.focusKeys.cnfrmnSttusCd == '50') {
        this.modalBtns1 = [];
      } else {
        this.modalBtns1 = [
          {name: '완료처리', callback: this.onOnofflineComplete},
          {name: '임시저장', callback: this.onOnofflineSave},
          {
            id: 'fileDelete_' + this.onofflineModalId,
            name: 'fileDelete',
            callback: this.onOnofflineDeleteFile,
            show: false,
          },
        ]; //20211208 fileDelete 추가 백그라운드 리로딩용
      }

      this.onOpenPopup(this.onofflineModalId);
    },
    async onReqSign() {
      var arrChkCnfrmnNo = this.getCheckedValues();
      if (arrChkCnfrmnNo.length < 1) {
        this.$alert('항목을 선택하세요');
        return;
      }

      var bolVali = true;
      var msgVali = '';

      var rowData;
      var ids = $('#' + this.sheetIdSub).jqGrid('getDataIDs');
      if (ids && ids.length > 0) {
        for (var i = 0; i <= ids.length - 1; i++) {
          rowData = $('#' + this.sheetIdSub).jqGrid('getRowData', ids[i]);

          for (var j = 0; j <= arrChkCnfrmnNo.length - 1; j++) {
            if (arrChkCnfrmnNo[j] == rowData.cnfrmnNo) {
              console.log('rowData.onOffDoc', rowData);
              //20210906 lgksja
              if (rowData.isDocBilled == 'Y') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 세금계산서가 발행된 건이라 서명요청을 할 수 없습니다.';
                break;
              }

              if (rowData.onOffDoc != 'ON') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 오프라인 확인서라 서명요청 할 수 없습니다.';
                break;
              }

              if (rowData.regYn != 'Y') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']의 고객 확인자 정보를 등록해 주세요.';
                break;
              }

              if (!(rowData.cnfrmnSttusCd == '10' || rowData.cnfrmnSttusCd == '30')) {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 서명요청 할 수 없는 상태입니다.';
                break;
              }

              //서명요청시 거래선번호가 있는지 확인을 위한 20241029 lgksja
              //20241119 무상은 제외
              if (rowData.tradeNo == '' && rowData.paymentType !='무상') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 세금계산서 발행을 위한 거래선코드가 없습니다.<br/>[OKSIS] "청구Master수정" 화면에서 거래선 코드를 등록해 주세요.';
                break;
              }
              //서명요청시 거래선번호가 있는지 확인을 위한 20241029 lgksja
            }
          }

          if (!bolVali) {
            break;
          }
        }
      }

      if (!bolVali) {
        this.$alert(msgVali);
        return;
      }

      var message = '';
      message += '증빙사진 첨부를 하지 않고 고객 날인을 요청하시면 이후 증빙사진을 첨부하여야 합니다.<br/><br/>';
      message += '* 완료확인서에 서명요청을 하시면 확인서 서명 가능합니다.';

      if (await this.$confirm(message)) {
        let param = {
          arrChkCnfrmnNo: arrChkCnfrmnNo,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };
        this.$loading('선택된 항목을 서명요청중 입니다.');
        var response = await axios
          .post('/confdoc/part/reqSign', param)
          .catch(e => console.error('서명요청을 실패했습니다. ', e));
        this.$loading('close');
        if (response.status == 200) {
          this.$alert('서명요청이 완료되었습니다.');
          this.onSearch();
        } else {
          this.$alert('서명요청을 실패하였습니다.');
        }
      }
    },
    async onRecovery() {
      var arrChkCnfrmnNo = this.getCheckedValues();
      if (arrChkCnfrmnNo.length < 1) {
        this.$alert('항목을 선택하세요');
        return;
      }

      var bolVali = true;
      var msgVali = '';

      var rowData;
      var ids = $('#' + this.sheetIdSub).jqGrid('getDataIDs');
      if (ids && ids.length > 0) {
        for (var i = 0; i <= ids.length - 1; i++) {
          rowData = $('#' + this.sheetIdSub).jqGrid('getRowData', ids[i]);

          for (var j = 0; j <= arrChkCnfrmnNo.length - 1; j++) {
            if (arrChkCnfrmnNo[j] == rowData.cnfrmnNo) {
              if (rowData.isDocBilled == 'Y') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 세금계산서가 발행된 건이라 회수를 할 수 없습니다.';
                break;
              }

              if (rowData.cnfrmnSttusCd == '10' || rowData.cnfrmnSttusCd == '30') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 회수 할 수 없는 상태입니다.';
                break;
              }
            }
          }

          if (!bolVali) {
            break;
          }
        }
      }

      if (!bolVali) {
        this.$alert(msgVali);
        return;
      }

      var message = '';
      message += '회수를 클릭하시면 고객 서명 요청단계 이전(회수)으로 변경됩니다.<br/><br/>';
      message += '* 고객정보 및 증빙사진은 언제든지 추가 할 수 있습니다.';
      //ITSR55206 서면일경우 고객 알림 안받는여부 체크 20220630      
      message += `<br/><br/>
                  <span style="float:left"><b>선택된 청구번호가 서면일 경우 고객 알림전송안함. &nbsp;</b></span>
                  &nbsp;<input type="checkbox" name="isCustNotCheck" style="display:block !important;float:left;margin-top: 2px;">`;
                  
      if (await this.$confirmChk(message,this.isCustAlarmNotCheck)) {
        /* let param = {
          arrChkCnfrmnNo: arrChkCnfrmnNo,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };
        this.$loading('선택된 항목을 회수중 입니다.');
        var response = await axios
          .post('/confdoc/part/recovery', param)
          .catch(e => console.error('회수를 실패했습니다. ', e));
        this.$loading('close');
        if (response.status == 200) {
          this.$alert('회수가 완료되었습니다.');
          this.onSearch();
        } else {
          this.$alert('회수를 실패하였습니다.');
        } */
        this.$loading('청구번호[' + arrChkCnfrmnNo + ']를 회수중 입니다.');
        var $this = this;
        setTimeout(() => {
          console.log("isCustAlarmNot:",this.isCustAlarmNot);
          let param = {
            arrChkCnfrmnNo: arrChkCnfrmnNo,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
            isCustAlarmNot : this.isCustAlarmNot,
          };    
          axios
            .post('/confdoc/part/recovery', param).then(function(response) {
              $this.$loading('close');
              if (response.status == 200) {
                $this.$alert('회수가 완료되었습니다.');
                $this.onSearch();
              } else {
                $this.$alert('회수를 실패하였습니다.');
              }
            })
            .catch(e => {
              $this.$loading('close');
              console.error('회수를 실패했습니다. ', e)}
            ); 
          
        },2000);
      }
    },
    async onSendAttach() {
      var arrChkCnfrmnNo = this.getCheckedValues();
      if (arrChkCnfrmnNo.length < 1) {
        this.$alert('항목을 선택하세요');
        return;
      }

      var bolVali = true;
      var msgVali = '';

      var rowData;
      var ids = $('#' + this.sheetIdSub).jqGrid('getDataIDs');
      if (ids && ids.length > 0) {
        for (var i = 0; i <= ids.length - 1; i++) {
          rowData = $('#' + this.sheetIdSub).jqGrid('getRowData', ids[i]);

          for (var j = 0; j <= arrChkCnfrmnNo.length - 1; j++) {
            if (arrChkCnfrmnNo[j] == rowData.cnfrmnNo) {
              if (rowData.cnfrmnSttusCd == '10') {
                bolVali = false;
                msgVali =
                  '청구번호[' +
                  rowData.rqestNo +
                  ']는 작업 중인 상태에서는 작업증빙사진 송부(카톡/메일)을 할 수 없습니다.';
                break;
              }

              if (rowData.regPhotoYn == 'N') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']는 작업증빙사진을 첨부해야 송부 할 수 있습니다.';
                break;
              }

              if (rowData.regYn != 'Y') {
                bolVali = false;
                msgVali = '청구번호[' + rowData.rqestNo + ']의 고객 확인자 정보를 등록해 주세요.';
                break;
              }
            }
          }

          if (!bolVali) {
            break;
          }
        }
      }

      if (!bolVali) {
        this.$alert(msgVali);
        return;
      }

      var message = '';
      message +=
        '해당버튼을 클릭하면 검수자의 전화번호 및 email정보로 작업증빙사진을 다운로드 할 수 있는 링크를 첨부하여 발송합니다.<br/><br/>';
      message += '* 고객이 받지 못하셨다고 하면 버튼을 다시 눌러 재송부 해주시기 바랍니다.';

      if (await this.$confirm(message)) {
        let param = {
          arrChkCnfrmnNo: arrChkCnfrmnNo,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        var response = await axios
          .post('/confdoc/part/sendAttach', param)
          .catch(e => console.error('작업증빙사진 첨부 송부에 실패했습니다. ', e));
        if (response.status == 200) {
          this.$alert('작업증빙사진 첨부 송부가 완료되었습니다.');
          this.onSearch();
        } else {
          this.$alert('작업증빙사진 첨부 송부에 실패하였습니다.');
        }
      }
    },
    getCheckedValues() {
      var arrChkCnfrmnNo = [];
      var objs = document.getElementsByName('chkCnfrmnNo');
      console.log('objs = ' + objs);
      var sz = objs.length;
      if (sz < 1) return;
      for (var i = 0; i < sz; i++) {
        if (objs[i].checked) {
          arrChkCnfrmnNo.push(objs[i].value);
        }
      }
      console.log('arrChkCnfrmnNo.length = ' + arrChkCnfrmnNo.length);
      return arrChkCnfrmnNo;
    },
    // eslint-disable-next-line
    async onView(data) {
      //리포트 정보 조회 후 오즈뷰어 팝업시킴.
      let param = {
        cnfrmnNo: data.cnfrmnNo,
      };

      var opt = {};
      const btns = [];

      if (data.cnfrmnSttusCd == '20') {
        btns.push({
          name: '서명', // 버튼명
          callback: () => {
            // 버튼 Event
            popupInterface[opt.name].vue.callEvent({
              name: 'ConfDocPartViewerPopup_Sign',
              param: data => {
                console.log('ConfDocPartViewerPopup_Sign');
                console.log(data);
                opt.window.close();
                this.$alert('서명이 완료되었습니다.');
                this.onSearch();
              },
            });
          },
        });
      }

      console.log('btns', btns);

      axios
        .post('/ozrpt/selectOzCntrwkFileName', param)
        .then(response => {
          //console.log('조회결과 response >>>', response);
          //console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          this.OZParam.OZFormParam = {
            signable: data.cnfrmnSttusCd == '20' ? 'Y' : 'N', //필수(*):서명가능여부(Y/N)
            signMthCd: '30',
            cnfrmnNo: response.data.cnfrmnNo, //확인서번호
            cntrwkNo: response.data.cntrwkNo, //계약서번호
            cntrwkOdr: response.data.cntrwkOdr, //계약차수
            userId: this.otisPage ? this.userInfo.empNo : this.customerPage ? this.userInfo.tradeNo : '',
          };

          opt = $open({
            componentPath: '@/view/confdoc/part/components/popup/ConfDocPartViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              cnfrmnNo: data.cnfrmnNo,
              OZParam: this.OZParam,
              otisPage: this.otisPage,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'ConfDocPartViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: (response.data.saCode == 'A' ? '부품교체확인서조회' : '부품판매확인서조회'), // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    async onDownloadOfflineConfDoc(data) {
      console.log('data', data);
      var fileName = data.fileName;
      // 부품교체확인서는 그룹번호에 #이 포함되므로 encodeURIComponent를 해야 #이 Get 방식으로 넘어갈때 짤리지 않는다.
      var url = '/confdoc/part/downloadOfflineConfDoc?cnfrmnNo=' + encodeURIComponent(data.cnfrmnNo);
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, fileName);
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('오프라인 확인서를 등록해야 다운로드 받을 수 있습니다.');
      }
    },
    async onCheckerPaste(data, selCnfrmnNo) {
      console.log('sourceCnfrmnNo', selCnfrmnNo);
      console.log('targetCnfrmnNo', data.cnfrmnNo);

      let param = {
        sourceCnfrmnNo: selCnfrmnNo,
        targetCnfrmnNo: data.cnfrmnNo,
        chngPgmId: this.$store.getters['mdi/currentUrls'].url,
      };

      if (!(await this.$confirm('청구번호[' + data.rqestNo + ']에 고객정보를 붙여넣기를 하시겠습니까?'))) return;

      var response = await axios
        .post('/confdoc/part/updateCheckerPaste', param)
        .catch(e => console.error('고객정보 붙여넣기에 실패했습니다. ', e));
      if (response.status == 200) {
        this.onSearch();
      } else {
        this.$alert('고객정보 붙여넣기에 실패했습니다.');
      }
    },
    async checkeSameListManage(param) {      
      console.log("param____",param);
      //let modalOnoffline = {};
      //modalOnoffline.cnfrmnNo = this.focusKeys.cnfrmnNo;
      //modalOnoffline.cnfrmnSttusCd = this.focusKeys.cnfrmnSttusCd;
      //modalOnoffline.readonly = this.focusKeys.cnfrmnSttusCd == '50' ? true : false;
      //modalOnoffline.onofflineModalId = this.onofflineModalId; //20211208 파일삭제 백그라운드 리로드
     
      this.setModalCheckeSameList(param);
      this.onOpenPopup(this.checkeSameListModalId);
    },
  },
  computed: {
    isCanPrint() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
    isCanOnOffManage() {
      return $isAdmin() ||
        this.isChargeEmp ||
        ((this.tradeType == 'OTIS' || this.tradeType == 'CCPY') && this.cnfrmnMain.cnfrmnSttusCd != '50')
        ? true
        : false;
    },
    isCanReqSign() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
    isCanRecovery() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
    isCanSendAttach() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
  },
  watch: {
    mapSubList: {
      //getters 의 mapSubList을 watch설정
      // eslint-disable-next-line
      handler(value, oldValue) {
        // mapSubList이 변경된다면 해당 함수 실행.
        // console.log(value); // 로직실행.
        // console.log('watch.setMapSubList');
        this.bindGrid();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
