<template>
  <iui-container type="css-flex" theme="bullet">
    <i-row height="70px" ref="srchBox" v-if="otisPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>고객/현장명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <!-- 20220103 lgksja 통합 엑셀 83번 
            <i-col-header>고객명</i-col-header>
            <i-col>
              <iui-text name="customerName" @change="onSetValue" @enter="onPreSearch" />                
            </i-col>
             -->
            <i-col-header>CC/팀</i-col-header>
            <i-col>
              <iui-text name="ccName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>영업담당</i-col-header>
            <i-col>
              <iui-text name="empName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <!--20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가-->
            <i-col-header>지사명</i-col-header>
            <i-col>
              <iui-text name="jisaNm" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>그룹명</i-col-header>
            <i-col>
              <iui-text name="groupNm" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <!--20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가-->
          </i-row>
          <i-row>
            <i-col-header>계약일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix="~"
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
            <i-col-header>계약번호</i-col-header>
            <i-col>
              <iui-text name="ctrtNo" @change="onSetValue" @enter="onPreSearch" width="150px" />
            </i-col>
            <i-col-header>유/무상</i-col-header>
            <i-col>
              <iui-select-old
                name="paymentType"
                :items="arrPaymentType"
                :value="search.paymentType"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header>진행상태</i-col-header>
            <i-col>
              <iui-select-old
                name="statusCode"
                :items="arrStatusCode"
                :value="search.statusCode"
                :enable="true"
                @change="onSetValue"
                required
              />
            </i-col>
            <i-col-header></i-col-header>
            <i-col> </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="40px" ref="srchBox" v-if="customerPage">
      <i-col>
        <iui-container type="table" theme="bullet">
          <i-row>
            <i-col-header>계약명</i-col-header>
            <i-col>
              <iui-text name="contractName" @change="onSetValue" @enter="onPreSearch" />
            </i-col>
            <i-col-header>계약일</i-col-header>
            <i-col
              ><span style="white-space:nowrap">
                <iui-datepicker-old
                  name="checkContDateStart"
                  suffix=" ~ "
                  @change="onSetValue($event, 'checkContDateStart')"
                  :value="search.checkContDateStart"/>
                &nbsp;<iui-datepicker-old
                  name="checkContDateEnd"
                  @change="onSetValue($event, 'checkContDateEnd')"
                  :value="search.checkContDateEnd"
              /></span>
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
    <i-row height="260px">
      <i-col>
        <pmis-content-box no-border-box>
          <template #title-right>
            <iui-button :enable="isCanPrint" v-show="isCanPrint" @click="onControlButton('print')"
              >서면(용)출력</iui-button
            >
            <iui-button :enable="isCanOnOffManage" v-show="isCanOnOffManage" @click="onControlButton('onOffManage')"
              >확인서(서면/전자)관리</iui-button
            >
            <iui-button :enable="isCanReqSign" v-show="isCanReqSign" @click="onControlButton('reqSign')"
              >전자서명요청</iui-button
            >
            <iui-button :enable="isCanRecovery" v-show="isCanRecovery" @click="onControlButton('recovery')"
              >회수</iui-button
            >
            <iui-button :enable="isCanSendAttach" v-show="isCanSendAttach" @click="onControlButton('sendAttach')"
              >작업증빙사진 송부(카톡/메일)</iui-button
            >
          </template>
          <iui-container type="jqgrid" :id="sheetId" :loading="listBoxLoading" style="width:99.9%" />
          <iui-modal-old
            :name="onofflineModalId"
            :title="'확인서(서면/전자)관리'"
            :btns="modalBtns1"
            width="800px"
            height="250px"
          >
            <Onoffline />
          </iui-modal-old>   
          <iui-modal-old
            :name="checkeSameListModalId"
            :title="'확인자 중복 목록'"
            :btns="modalBtns2"
            width="800px"
            height="370px"
          >
            <CheckeSameList />
          </iui-modal-old>       
        </pmis-content-box>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
import Onoffline from '../../components/popup/Onoffline.vue';
import CheckeSameList from '../../components/popup/CheckeSameList.vue';
export default {
  name: 'constcmpl-list',
  props: {
    otisPage: {
      // OSE 페이지
      type: Boolean,
      default: false,
    },
    customerPage: {
      // 업체 페이지
      type: Boolean,
      default: false,
    },
  },
  components: {
    Onoffline,
    CheckeSameList,
  },
  beforeCreate() {
    $mapGetters(this, ['cnfrmnMain', 'tradeType', 'isChargeEmp']);
    $mapMutations(this, ['setModalOnoffline','setModalCheckeSameList']);
  },
  created() {
    this.addEvent({name: 'ConstCmplMainList_PreSearch', func: this.onPreSearch});
    this.addEvent({name: 'ConstCmplMainList_Search', func: this.onSearch});

    this.addEvent({name: 'ConstCmplCheckeSameListManage_Popup', func: this.checkeSameListManage});
  },
  data() {
    return {
      //sheetId: 'sheet',// + this._uid,
      isCustAlarmNot : false,
      sheetId: 'sheet' + this.$store.getters['mdi/currentUrls'].menuId,

      onofflineModalId: 'onofflineModal' + this._uid,
      modalBtns1: [
        {name: '완료처리', callback: this.onOnofflineComplete},
        {name: '임시저장', callback: this.onOnofflineSave},
      ],

      checkeSameListModalId: 'checkeSameListModal' + this._uid,

      modalBtns2: [
        {name: '저장', callback: this.onCheckerSave},
        
      ],

      modalNm: '',

      arrPaymentType: [
        {text: '전체', value: ''},
        {text: '무상', value: '무상'},
        {text: '유상', value: '유상'},
      ],
      arrStatusCode: [
        {text: '전체', value: ''},
        {text: '작성중', value: '10'},
        {text: '서명요청', value: '20'},
        {text: '회수', value: '30'},
        // {text: '서명완료', value: '40'},
        {text: '완료', value: '50'},
      ],

      search: {
        contractName: '',
        customerName: '',
        ccName: '',
        empName: '',
        checkContDateStart: '',
        checkContDateEnd: '',
        isOtisPage: this.otisPage,
        isCustomerPage: this.customerPage,
        ctrtNo: '',
        paymentType: '',
        statusCode: '',
        summaryYm: '',
        empNo: '',

        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        jisaNm: '',
        groupNm: '',
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
      },

      listBoxLoading: false,

      focusKeys: {
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        docCat1: '',
        cnfrmnNo: '',
        cnfrmnSttusCd: '',
        onOffDoc: '',
        regYn: '',
        regPhotoYn: '',
      },

      modalOZViewerBtns: [],
      OZParam: {},
    };
  },
  mounted() {
    if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
      // if (this.customerPage) {
        this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -3);
      // } else if (this.otisPage) {
      //   this.search.checkContDateStart = $_addMonth($_getCurrentDate(), -1);
      // }
      this.search.checkContDateEnd = $_getCurrentDate();
    }

    const OPT = {
      Cols: [
        // eslint-disable-next-line
        {
          name: '보기',
          label: '계약서',
          width: 50,
          align: 'center',
          // eslint-disable-next-line
          formatter: function(cellvalue, options, rowObject) {
            cellvalue = true;
            return cellvalue == true ? '<button >보기</button>' : '';
          },
          cellattr: $_rowMearge,
        },
        {name: 'onOffDoc', label: '확인서', width: 0, hidden: true},
        // eslint-disable-next-line
        {
          name: 'onOffDocNm',
          label: '확인서',
          width: 60,
          align: 'center',
          formatter: function(cellvalue, options, rowObject) {
            /*20210909 lgksja N/A 추가 ~S*/
            //return (cellvalue == 'ON' ? '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>' : '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>');
            var returnStr =
              cellvalue == 'ON'
                ? '<button style="border:1px solid #668CF3;background-color:#668CF3">전자</button>'
                : '<button style="border:1px solid #19B70A;background-color:#19B70A">서면</button>';
            /*
            if(rowObject.cnfrmnSttusCd == '50' && rowObject.fileNo == '0'){
              returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">N/A</button>';
            }
            */
            //20211208 완료일때 조건 삭제
            if (
              /*rowObject.cnfrmnSttusCd == '50'&& */ rowObject.onOffDocNm !== 'ON' &&
              (rowObject.fileNo == '0' || rowObject.confDocFileName == null)
            ) {
              returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">N/A</button>';
            } else if (
              rowObject.onOffDocNm == 'ON' &&
              rowObject.cnfrmnSttusCd == '50' &&
              rowObject.fileNo == '0' &&
              rowObject.signDt == null
            ) {
              //20211215 엑셀 99번
              returnStr = '<button style="border:1px solid #808080;background-color:#808080" title="없음">N/A</button>';
            }
            return returnStr;
            /*20210909 lgksja N/A 추가 ~S*/
          },
          cellattr: $_rowMearge,
        },
      ],
    };
    OPT.Cols.push({
      name: 'signDt',
      label: '서명일',
      width: 75,
      align: 'center',
      sorttype: 'date',
      formatter: 'date',
      formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
      cellattr: $_rowMearge,
    });
    OPT.Cols.push(
      {name: 'cnfrmnSttusCd', label: '상태', width: 0, hidden: true},
      //{ name: "cnfrmnSttusNm",              label: "상태",                    width: 80,    align: "center", cellattr: $_rowMearge },
      {
        name: 'cnfrmnSttusNm',
        label: '상태',
        width: 60,
        align: 'center',
        formatter: function(cellvalue, options, rowObject) {
          //20210909 lgksja formatter 추가
          var returnStr = cellvalue;
          if (rowObject.cnfrmnSttusCd == '50' && rowObject.isDocBilled == 'Y') {
            //완료 이고 세금계산서 발행이면
            returnStr = 'TAX';
          }
          return returnStr;
        },
        cellattr: $_rowMearge,
      }
    );
    const OPT_ADD = {
      Cols: [
        // {name: 'docCat1Nm', label: '표준<br/>/비표준', width: 50, align: 'center', cellattr: $_rowMearge},
        // 20241202 아래로 변경
        {name: 'docCat1Nm', label: '계약서형태', width: 80, align: 'center', cellattr: $_rowMearge},
        {name: 'signType', label: '전자<br/>/서면', width: 0, hidden: true},
        {name: 'signTypeNm', label: '전자<br/>/서면', width: 50, align: 'center', cellattr: $_rowMearge},
        {name: 'teamNoDesc', label: 'CCT', width: 50, align: 'left', cellattr: $_rowMearge},
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        //{ name: "bonbuNm",                    label: "본부",                    width: 80,    align: "left", cellattr: $_rowMearge },
        {name: 'jisaNm', label: '지사', width: 80, align: 'left', cellattr: $_rowMearge},
        {name: 'groupNm', label: '그룹', width: 60, align: 'left', cellattr: $_rowMearge},
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        {name: 'tradeNo', label: '고객번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'tradeName', label: '고객명', width: 120, align: 'left', cellattr: $_rowMearge},
        {name: 'mainNo', label: '관리번호', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'mainName', label: '현장명', width: 170, align: 'left', cellattr: $_rowMearge},
        {name: 'srpType', label: '상품', width: 40, align: 'center'},
        {name: 'subcontractQhNm', label: '세부', width: 60, align: 'center'},
        {name: 'suriNo', label: '번호', width: 80, align: 'center'},
        {name: 'suriSeq', label: '차수', width: 40, align: 'center'},
        {name: 'pmEmpName', label: 'PM', width: 70, align: 'center', cellattr: $_rowMearge},
        {name: 'threePiNm', label: '3PI', width: 120, align: 'left', cellattr: $_rowMearge},
        {name: 'contEmpNm', label: '영업담당', width: 70, align: 'center', cellattr: $_rowMearge},
        {
          name: 'contDate',
          label: '계약일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'buildStartDate',
          label: '계약시작일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'buildEndDate',
          label: '계약종료일',
          width: 70,
          align: 'center',
          sorttype: 'date',
          formatter: 'date',
          formatoptions: {userLocalTime: false, srcformat: 'ISO8601Long', newformat: 'Y-m-d'},
          cellattr: $_rowMearge,
        },
        {
          name: 'biddingAmt',
          label: '계약금액<br/>(VAT별도)',
          width: 80,
          align: 'right',
          formatter: 'number',
          formatoptions: {decimalSeparator: '.', thousandsSeparator: ',', decimalPlaces: 0, defaultValue: '0'},
          cellattr: $_rowMearge,
        },
        {name: 'cnfrmnNo', label: 'cnfrmnNo', width: 0, hidden: true},
        {name: 'constructNo', label: 'constructNo', width: 0, hidden: true},
        {name: 'constructSeq', label: 'constructSeq', width: 0, hidden: true},
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
        {name: 'ctrtSttusCd', label: 'ctrtSttusCd', width: 0, hidden: true},
        {name: 'docCat1', label: 'docCat1', width: 0, hidden: true},
        {name: 'fileName', label: 'fileName', width: 0, hidden: true},
        {name: 'fileNo', label: 'fileNo', width: 0, hidden: true},
        {name: 'confDocFileName', label: 'confDocFileName', width: 0, hidden: true},
        {name: 'tradeType', label: 'tradeType', width: 0, hidden: true},
        {name: 'regYn', label: 'regYn', width: 0, hidden: true},
        {name: 'regPhotoYn', label: 'regPhotoYn', width: 0, hidden: true},
        {name: 'isDocBilled', label: 'isDocBilled', width: 0, hidden: true},
        {name: 'isNotiFlag', label: 'isNotiFlag', width: 0, hidden: true},
      ],
    };
    OPT.Cols = OPT.Cols.concat(OPT_ADD.Cols);
    /* 20210903 lgksja ~ s*/
    OPT.Cols.push(
      {name: 'sendngDt', label: '서명요청일<br/>(서명확정일)', width: 80, align: 'center', cellattr: $_rowMearge},
      {name: 'sendngEmplId', label: '서명요청자', width: 70, align: 'center', cellattr: $_rowMearge}
    );
    OPT.Cols.push({
      name: 'slngEmplId',
      label: '서명자<br/>(확정자)',
      width: 60,
      align: 'center',
      cellattr: $_rowMearge,
    });
    /* 20210903 lgksja ~ e*/
    const me = this;
    var $grid = $('#' + me.sheetId);
    $grid.jqGrid({
      height: 120,
      width: 1135,
      colModel: OPT.Cols,
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: false,
      autowidth: false,
      shrinkToFit: false,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        console.log('aaaa', $('#' + me.sheetId).jqGrid('getGridParam').iColByName['보기']);
        console.log('iCol', iCol);
        /*if (
          iCol != $('#' + me.sheetId).jqGrid('getGridParam').iColByName['보기'] &&
          iCol != $('#' + me.sheetId).jqGrid('getGridParam').iColByName['onOffDocNm']
        ) {
          //20220127 lgksja 보기 눌렀을경우 데이타 로딩 안되게 처리
          me.$emit('click', rowData);
          me.setFocusKeys(rowData);
        }*/
        if (me.focusKeys.ctrtNo!=rowData.ctrtNo 
            || me.focusKeys.ctrtOdr!=rowData.ctrtOdr 
            || me.focusKeys.ctrtDivCd!=rowData.ctrtDivCd
            || me.focusKeys.cnfrmnNo!=rowData.cnfrmnNo) {
          // 20211101 lgksja 보기일경우는 팝업만 열리게
          me.$emit('click', rowData);          
        }
        me.setFocusKeys(rowData);

        if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['보기']) {
          if (rowData.signType == 'ON') {
            me.onView(rowData);
          } else {
            me.onDownloadOfflinePdf(rowData);
          }
        } else if (iCol == $('#' + me.sheetId).jqGrid('getGridParam').iColByName['onOffDocNm']) {
          if (rowData.cnfrmnSttusCd == '50' && rowData.fileNo == '0') {
            //파일이 없다면 아무 것도 안함
          } else {
            if (rowData.onOffDoc == 'ON') {
              if (rowData.cnfrmnSttusCd == '50') {
                //20211110 lgksja 86번
                me.onDownloadOfflineConfDoc(rowData);
              } else {
                me.onViewConfDoc(rowData);
              }
            } else {
              if (rowData.confDocFileName !== '' && rowData.confDocFileName != null) {
                //20211208조건 추가
                me.onDownloadOfflineConfDoc(rowData);
              }
            }
          }
          return false;
        }
      },
    });

    var groups = [];
    groups.push({startColumnName: 'srpType', numberOfColumns: 2, titleText: '계약유형'});
    groups.push({startColumnName: 'suriNo', numberOfColumns: 2, titleText: '계약번호'});

    $grid.jqGrid('setGroupHeaders', {
      useColSpanStyle: true,
      groupHeaders: groups,
    });

    $gridAutoResize($grid);
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 1000);

    $initSrchShowButton({
      srchBox: this.$refs.srchBox,
      srchList: this.$parent.$parent,
    });
  },
  methods: {
    isCustAlarmNotCheck(flag) {
      this.isCustAlarmNot = flag;
    },
    setFocusKeys(rowData) {
      this.focusKeys.ctrtNo = rowData.ctrtNo;
      this.focusKeys.ctrtOdr = rowData.ctrtOdr;
      this.focusKeys.ctrtDivCd = rowData.ctrtDivCd;
      this.focusKeys.docCat1 = rowData.docCat1;
      this.focusKeys.cnfrmnNo = rowData.cnfrmnNo;
      this.focusKeys.cnfrmnSttusCd = rowData.cnfrmnSttusCd;
      this.focusKeys.onOffDoc = rowData.onOffDoc;
      this.focusKeys.regYn = rowData.regYn;
      this.focusKeys.regPhotoYn = rowData.regPhotoYn;
      this.focusKeys.isDocBilled = rowData.isDocBilled;
    },
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth - 10, false);
    },
    onSetValue(e, t) {
      let target = typeof e == 'string' ? t : e.target.name;
      let value = typeof e == 'string' ? e : e.target.value;
      this.search[target] = value;
    },
    onPreSearch() {
      var urls = this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0];
      if (urls !== undefined) {
        var params = urls.params;
        console.log('params', params);
        if (params !== undefined) {
          this.search.paymentType = '';
          this.search.statusCode = '';
          this.search.summaryYm = '';
          this.search.empNo = '';

          this.search.paymentType = params.paymentType;
          this.search.statusCode = params.statusCode;
          this.search.summaryYm = params.summaryYm;
          this.search.empNo = params.empNo;

          this.$router.app.urls.filter(data => data.menuId == this.$route.name)[0].params = undefined;
        }
      }

      this.focusKeys.ctrtNo = '';
      this.focusKeys.ctrtOdr = '';
      this.focusKeys.ctrtDivCd = '';
      this.focusKeys.docCat1 = '';
      this.focusKeys.cnfrmnNo = '';
      this.focusKeys.cnfrmnSttusCd = '';
      this.focusKeys.onOffDoc = '';
      this.focusKeys.regYn = '';
      this.focusKeys.regPhotoYn = '';
      this.focusKeys.isDocBilled = '';
      this.onSearch();
    },
    async onSearch() {
      if (this.search.summaryYm === undefined || this.search.summaryYm == '') {
        // 계약번호가 입력되면 청구일자와 무관하게 조회하도록
        if (this.search.ctrtNo === undefined || this.search.ctrtNo == '') {
          // 계약일 기간 비워져 있을시에 alert
          if (!this.search.checkContDateStart || !this.search.checkContDateEnd) {
            this.$alert('검색하실 계약일의 시작일과 종료일을 선택해주세요.');
            return;
          }

          if (parseInt(this.search.checkContDateStart) > parseInt(this.search.checkContDateEnd)) {
            this.$alert('계약일의 시작일은 종료일 보다 뒤일 수 없습니다.<br>시작일을 다시 선택해주세요.');
            return;
          }

          var sDate = new Date(
            this.search.checkContDateStart.substring(0, 4),
            this.search.checkContDateStart.substring(4, 6),
            this.search.checkContDateStart.substring(6, 8)
          );

          var eDate = new Date(
            this.search.checkContDateEnd.substring(0, 4),
            this.search.checkContDateEnd.substring(4, 6),
            this.search.checkContDateEnd.substring(6, 8)
          );

          var dif = eDate - sDate;
          var cDay = 24 * 60 * 60 * 1000; // 시 * 분 * 초 * 밀리세컨
          var cMonth = cDay * 30; // 월 만듬
          // var cYear = cMonth * 12; // 년 만듬

          // console.log('sDate', sDate);
          // console.log('eDate', eDate);
          // console.log('dif', dif);
          // console.log('parseInt(dif/cMonth)', parseInt(dif/cMonth));

          // if (this.customerPage) {
            if (parseInt(dif / cMonth) > 3) {
              this.$alert('계약일 기간을 3개월 이내로 검색해주세요.');
              return;
            }
          // } else if (this.otisPage) {
          //   if (parseInt(dif / cMonth) > 1) {
          //     this.$alert('계약일 기간을 1개월 이내로 검색해주세요.');
          //     return;
          //   }
          // }
        } else {
          this.search.checkContDateStart = '';
          this.search.checkContDateEnd = '';
        }
      } else {
        this.search.checkContDateStart = '';
        this.search.checkContDateEnd = '';
      }

      this.listBoxLoading = true;

      let param = {
        contractName: this.search.contractName,
        customerName: this.search.customerName,
        ccName: this.search.ccName,
        empName: this.search.empName,
        checkContDateStart: this.search.checkContDateStart,
        checkContDateEnd: this.search.checkContDateEnd,
        otisPage: this.search.isOtisPage,
        customerPage: this.search.isCustomerPage,
        signType: this.search.signType,
        ctrtNo: this.search.ctrtNo,
        paymentType: this.search.paymentType,
        statusCode: this.search.statusCode,
        summaryYm: this.search.summaryYm,
        empNo: this.search.empNo,

        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
        jisaNm: this.search.jisaNm,
        groupNm: this.search.groupNm,
        /*20220103 lgksja 엑셀 70 본부삭제 그룹 지사 추가*/
      };

      let response = null;
      try {
        response = await axios.post('/confdoc/constcmpl/selectMainList', param);
        this.listBoxLoading = false;
        $('#' + this.sheetId)
          .clearGridData(true)
          .setGridParam({data: response.data})
          .trigger('reloadGrid');

        // TODO에서 넘어온 파라메터 값으로 조회한 이후 해당 파라메터들을 초기화해서 검색 버튼을 클릭했을 때 정상적으로 조회 될 수 있게 한다.
        if (this.search.summaryYm !== undefined && response.data.length == 0) {
          this.search.paymentType = '';
          this.search.statusCode = '';
          this.search.summaryYm = '';
          this.search.empNo = '';
        }

        var rowData;
        var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
        if (ids && ids.length > 0) {
          if (this.focusKeys.ctrtNo && this.focusKeys.ctrtOdr && this.focusKeys.ctrtDivCd) {
            for (var i = 0; i <= ids.length - 1; i++) {
              rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[i]);

              if (
                rowData['ctrtNo'] == this.focusKeys.ctrtNo &&
                rowData['ctrtOdr'] == this.focusKeys.ctrtOdr &&
                rowData['ctrtDivCd'] == this.focusKeys.ctrtDivCd
              ) {
                // console.log("일치하는 Row 찾아 조회");
                $('#' + this.sheetId).jqGrid('setSelection', ids[i], true);
                this.$emit('click', rowData);
                this.setFocusKeys(rowData);
                break;
              }
            }
          } else {
            // console.log("일치하는 Row 못찾아 첫번째 행을 조회");
            $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
            rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
            this.$emit('click', rowData);
            this.setFocusKeys(rowData);
          }
        } else {
          // console.log("검색된 데이터 없어서 리셋");
          this.$emit('resetValues', null);
        }
      } catch (ex) {
        console.log(ex);
        this.listBoxLoading = false;
      }
    },
    async onDownloadOfflinePdf(data) {
      var fileName = '';
      var url = '';
      if (data.ctrtDivCd == 'TC') {
        fileName = data.fileName;
        url = '/tcontract/downloadOfflinePdf?suriNo=' + data.ctrtNo + '&suriSeq=' + data.ctrtOdr;
        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });

        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(response.data, fileName);
          } else {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      } else if (data.ctrtDivCd == 'OC') {
        fileName = data.fileName;
        url = '/ocontract/downloadOfflinePdf?checkContNo=' + data.ctrtNo + '&checkSeq=' + data.ctrtOdr;
        const response = await axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        });

        if (response.data.size > 0) {
          const downUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downUrl;
          if (typeof window.navigator.msSaveBlob === 'function') {
            window.navigator.msSaveBlob(response.data, fileName);
          } else {
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          this.$alert('서면으로 체결 된 계약서는 OKSS에서 등록해야 다운로드 받을 수 있습니다.');
        }
      }
    },
    async onView(data) {
      console.log('onView');
      console.log('ctrtNo', data.ctrtNo);
      console.log('ctrtOdr', data.ctrtOdr);
      console.log('ctrtDivCd', data.ctrtDivCd);
      console.log('docCat1', data.docCat1);

      var signable = 'N';
      var signerSeCd = '10';
      var tradeNo = '';

      console.log('signable', signable);
      console.log('signerSeCd', signerSeCd);
      console.log('tradeNo', tradeNo);

      //계약정보 조회 후 오즈뷰어 팝업시킴.
      let param = {
        checkContNo: data.ctrtNo,
        checkSeq: data.ctrtOdr,
        manageCd: data.docCat1,
      };

      // eslint-disable-next-line
      var opt = {};
      const btns = [];
      
      //20221123 ITSR62079
      var opt_param = {
                    ctrtNo: data.ctrtNo,
                    ctrtOdr: data.ctrtOdr,
                    ctrtDivCd: data.ctrtDivCd,
                    docCat1: data.docCat1,
                    ctrtSttusCd: data.ctrtSttusCd,
                  }; 

      if (data.ctrtDivCd == 'TC') {
        if (data.ctrtOdr == '00') {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/tContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                //ctrtNo: this.focusKeys.ctrtNo,
                //ctrtOdr: this.focusKeys.ctrtOdr,
                //ctrtDivCd: this.focusKeys.ctrtDivCd,
                //docCat1: this.focusKeys.docCat1,
                ...opt_param,
                OZParam: {},
                otisPage: this.otisPage,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'TContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '계약서조회', // 팝업 제목 (생략가능)
            });
          } else {
            var uri = '/ozrpt/selectOzTContract';
            // 발주서 리포트 코드 값이 바뀌면 수정해야 함.
            if (data.docCat1 == 'C20' || data.docCat1 == 'C50' || data.docCat1 == 'C80') {
              uri = '/ozrpt/selectOzTCtrtOrder';
            }
            axios
              .post(uri, param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/tContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    //ctrtNo: data.ctrtNo,
                    //ctrtOdr: data.ctrtOdr,
                    //ctrtDivCd: data.ctrtDivCd,
                    //docCat1: data.docCat1,
                    //ctrtSttusCd: data.ctrtSttusCd,
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: this.otisPage,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'TContractViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '계약서조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        } else {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/tContract/components/popup/ChangeContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                //ctrtNo: this.focusKeys.ctrtNo,
                //ctrtOdr: this.focusKeys.ctrtOdr,
                //ctrtDivCd: this.focusKeys.ctrtDivCd,
                //docCat1: this.focusKeys.docCat1,
                ...opt_param,
                OZParam: {},
                otisPage: this.otisPage,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'ChangeContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '변경합의서조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzTCtrtChange', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선코드(로그인한사용자)
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/tContract/components/popup/ChangeContractViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    //ctrtNo: this.focusKeys.ctrtNo,
                    //ctrtOdr: this.focusKeys.ctrtOdr,
                    //ctrtDivCd: this.focusKeys.ctrtDivCd,
                    //docCat1: this.focusKeys.docCat1,
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: this.otisPage,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'ChangeContractViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '변경합의서조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        }
      } else if (data.ctrtDivCd == 'OC') {
        if (data.ctrtOdr == '00') {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                //ctrtNo: this.focusKeys.ctrtNo,
                //ctrtOdr: this.focusKeys.ctrtOdr,
                //ctrtDivCd: this.focusKeys.ctrtDivCd,
                //docCat1: this.focusKeys.docCat1,
                ...opt_param,
                OZParam: {},
                otisPage: true,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '계약서조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzOContract', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                  signMthCd: '',
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/oContract/components/popup/ContractViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    //ctrtNo: this.focusKeys.ctrtNo,
                    //ctrtOdr: this.focusKeys.ctrtOdr,
                    //ctrtDivCd: this.focusKeys.ctrtDivCd,
                    //docCat1: this.focusKeys.docCat1,
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: true,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'OContractViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '계약서조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        } else {
          if (data.docCat1 == '000') {
            // eslint-disable-next-line
            opt = $open({
              componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
              param: {
                //ctrtNo: this.focusKeys.ctrtNo,
                //ctrtOdr: this.focusKeys.ctrtOdr,
                //ctrtDivCd: this.focusKeys.ctrtDivCd,
                //docCat1: this.focusKeys.docCat1,
                ...opt_param,
                OZParam: {},
                otisPage: true,
              }, // 파라미터 (생략가능)
              btns: btns, // 팝업 버튼 (생략가능)
              name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
              option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
              title: '공문조회', // 팝업 제목 (생략가능)
            });
          } else {
            axios
              .post('/ozrpt/selectOzOContract', param)
              .then(response => {
                // console.log('조회결과 response >>>', response);
                // console.log('조회결과 response.data >>>', response.data);

                this.OZParam = Object.assign({}, response.data.jsonData);

                this.OZParam.reportname = response.data.reportPathName;
                this.OZParam.OZFormParam = {
                  signable: signable, // 필수(*):디지털 서명가능여부(Y/N)
                  signerSeCd: signerSeCd, // 필수(*):서명자구분코드(10:고객,20:대리인,30:OSSEP)
                  tradeNo: tradeNo, // [사인할경우만 필수(signable='Y')]거래선번호(로그인한사용자)
                };

                // eslint-disable-next-line
                opt = $open({
                  componentPath: '@/view/contract/oContract/components/popup/OfficialDocumentViewer.vue', // 컴포넌트 경로 (필수)
                  param: {
                    //ctrtNo: this.focusKeys.ctrtNo,
                    //ctrtOdr: this.focusKeys.ctrtOdr,
                    //ctrtDivCd: this.focusKeys.ctrtDivCd,
                    //docCat1: this.focusKeys.docCat1,
                    ...opt_param,
                    OZParam: this.OZParam,
                    otisPage: true,
                  }, // 파라미터 (생략가능)
                  btns: btns, // 팝업 버튼 (생략가능)
                  name: 'OfficialDocumentViewer', // 팝업 윈도우 이름 (생략가능)
                  option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
                  title: '공문조회', // 팝업 제목 (생략가능)
                });
              })
              .catch(function(error) {
                console.log('========error====================');
                console.log(error);
              });
          }
        }
      }
    },
    onOpenPopup(modalNm) {
      this.modalNm = modalNm;
      this.$modal.show(modalNm);
    },
    onOnofflineComplete() {
      this.callEvent({
        name: 'Onoffline_Complete',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.onSearch();
        },
      });
    },
    onOnofflineSave() {
      this.callEvent({
        name: 'Onoffline_Save',
        param: data => {
          console.log(data);
          this.$modal.hide(this.modalNm);
          this.onSearch();
        },
      });
    },
    onOnofflineDeleteFile() {
      //20211208 파일삭제용 추가
      this.callEvent({
        name: 'Onoffline_DeleteFile',
        param: data => {
          console.log(data);
          //this.$modal.hide(this.modalNm);
          this.onSearch();
        },
      });
    },
    onCheckerSave() {
      console.log("aaaaaaaaaaaaaaaaaaaaaa");
      console.log(" this.modalNm2", this.modalNm);
      this.callEvent({
        name: 'modal_Checker_Save',
        param: data => {
          console.log(data);
         
          this.$modal.hide(this.modalNm);
          //this.onSearch();
        },
      });
    },

    async onControlButton(type) {
      const me = this;

      console.log('ctrtNo', me.cnfrmnMain.ctrtNo);
      console.log('ctrtOdr', me.cnfrmnMain.ctrtOdr);
      console.log('ctrtDivCd', me.cnfrmnMain.ctrtDivCd);
      console.log('cnfrmnNo', me.cnfrmnMain.cnfrmnNo);

      /*20211215 엑셀91번 ‘작성중’’회수’ 상태에서 출고완료가 아니면*/
      if (
        me.cnfrmnMain.isNotiFlag != '' &&
        (me.cnfrmnMain.cnfrmnSttusCd == '10' || me.cnfrmnMain.cnfrmnSttusCd == '30')
      ) {
        if (type == 'onOffManage' || type == 'reqSign' || type == 'recovery') {
          if (me.cnfrmnMain.isNotiFlag == 'D') {
            //this.$alert('유상공사의 청구품목 중 출고 안 된 품목이 있습니다. 출고 상태를 확인해주세요.');
            //20220125 김영하책임요청
            this.$alert(
              '유상공사의 청구품목이 출고완료가 아니거나 미출고 대상이 삭제되지 않았습니다. 청구를 확인하세요'
            );
          } else {
            //this.$alert('무상공사의 청구품목 중 출고 안 된 품목이 있습니다. 출고 상태를 확인해주세요');
            //20220125 김영하책임요청
            this.$alert(
              '무상공사의 청구품목이 출고완료가 아니거나 미출고 대상이 삭제되지 않았습니다. 청구를 확인하세요'
            );
          }
          return;
        }
      }

      if (type == 'print') {
        me.onPrint();
      } else if (type == 'onOffManage') {
        me.onOnOffManage();
      } else if (type == 'reqSign') {
        me.onReqSign();
      } else if (type == 'recovery') {
        me.onRecovery();
      } else if (type == 'sendAttach') {
        me.onSendAttach();
      }
    },
    async onPrint() {
      //리포트 정보 조회 후 오즈뷰어 팝업시킴.
      let param = {
        cnfrmnNo: this.focusKeys.cnfrmnNo,
      };

      const btns = [];

      console.log('btns', btns);

      let blinkCnfrmn = false;
      if (await this.$confirm('확인자/서명자 정보를 공란으로 출력하시겠습니까?')) {
        blinkCnfrmn = true;
      }

      axios
        .post('/ozrpt/selectOzCntrwkFileName', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          this.OZParam.OZFormParam = {
            signable: 'N', //필수(*):서명가능여부(Y/N)
            signMthCd: '30',
            onlineYn: 'N',
            cnfrmnNo: response.data.cnfrmnNo, //확인서번호
            cntrwkNo: response.data.cntrwkNo, //계약서번호
            cntrwkOdr: response.data.cntrwkOdr, //계약차수
            blinkCnfrmn: blinkCnfrmn, // 확인자 공란 여부
            userId: this.otisPage ? this.userInfo.empNo : this.customerPage ? this.userInfo.tradeNo : '',
          };

          $open({
            componentPath: '@/view/confdoc/constcmpl/components/popup/ConfDocConstCmplPrint.vue', // 컴포넌트 경로 (필수)
            param: {
              cnfrmnNo: this.focusKeys.cnfrmnNo,
              OZParam: this.OZParam,
              otisPage: this.otisPage,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'ConfDocConstCmplPrint', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '공사완료확인서출력', // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    async onOnOffManage() {
      let modalOnoffline = {};
      modalOnoffline.cnfrmnNo = this.focusKeys.cnfrmnNo;
      modalOnoffline.cnfrmnSttusCd = this.focusKeys.cnfrmnSttusCd;
      modalOnoffline.readonly = this.focusKeys.cnfrmnSttusCd == '50' ? true : false;
      modalOnoffline.onofflineModalId = this.onofflineModalId; //20211208 파일삭제 백그라운드 리로드
      this.setModalOnoffline(modalOnoffline);
      console.log('modalOnoffline', modalOnoffline);
      if (this.focusKeys.cnfrmnSttusCd == '20' || this.focusKeys.cnfrmnSttusCd == '50') {
        this.modalBtns1 = [];
      } else {
        this.modalBtns1 = [
          {name: '완료처리', callback: this.onOnofflineComplete},
          {name: '임시저장', callback: this.onOnofflineSave},
          {
            id: 'fileDelete_' + this.onofflineModalId,
            name: 'fileDelete',
            callback: this.onOnofflineDeleteFile,
            show: false,
          },
        ]; //20211208 fileDelete 추가 백그라운드 리로딩용
      }

      this.onOpenPopup(this.onofflineModalId);
    },
    async onReqSign() {
      console.log('this.focusKeys', this.focusKeys);
      //20210916 lgksja
      if (this.focusKeys.isDocBilled == 'Y') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']는 세금계산서가 발행된 건이라 서명요청을 할 수 없습니다.');
        return;
      }

      if (this.focusKeys.onOffDoc != 'ON') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']는 오프라인 확인서라 서명요청 할 수 없습니다.');
        return;
      }

      if (this.focusKeys.regYn != 'Y') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']의 고객 확인자 정보를 등록해 주세요.');
        return;
      }

      if (!(this.focusKeys.cnfrmnSttusCd == '10' || this.focusKeys.cnfrmnSttusCd == '30')) {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']는 서명요청 할 수 없는 상태입니다.');
        return;
      }

      var message = '';
      message += '증빙사진 첨부를 하지 않고 고객 날인을 요청하시면 이후 증빙사진을 첨부하여야 합니다.<br/><br/>';
      message += '* 완료확인서에 서명요청을 하시면 확인서 서명 가능합니다.';

      if (await this.$confirm(message)) {
        let param = {
          cnfrmnNo: this.focusKeys.cnfrmnNo,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };
        this.$loading('선택된 항목을 서명요청중 입니다.');
        var response = await axios
          .post('/confdoc/constcmpl/reqSign', param)
          .catch(e => console.error('서명요청을 실패했습니다. ', e));
        this.$loading('close');
        if (response.status == 200) {
          this.$alert('서명요청이 완료되었습니다.');
          this.onSearch();
        } else {
          this.$alert('서명요청을 실패하였습니다.');
        }
      }
    },
    async onRecovery() {
      console.log('this.focusKeys', this.focusKeys);

      if (this.focusKeys.isDocBilled == 'Y') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']는 세금계산서가 발행된 건이라 회수를 할 수 없습니다.');
        return;
      }

      if (this.focusKeys.cnfrmnSttusCd == '10' || this.focusKeys.cnfrmnSttusCd == '30') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']는 회수 할 수 없는 상태입니다.');
        //this.$info("aaaaaa");
        return;
      }

      var message = '';
      message += '회수를 클릭하시면 고객 서명 요청단계 이전(회수)으로 변경됩니다.<br/><br/>';
      message += '* 고객정보 및 증빙사진은 언제든지 추가 할 수 있습니다.';

      //ITSR55206 서면일경우 고객 알림 안받는여부 체크 20220630
      if(this.focusKeys.onOffDoc == 'OFF'){
        message += `<br/><br/>
                    <span style="float:left"><b>고객 알림전송안함.&nbsp;</b></span>
                    &nbsp;<input type="checkbox" name="isCustNotCheck" style="display:block !important;float:left;margin-top: 2px;">
                    `;
      }
      if (await this.$confirmChk(message,this.isCustAlarmNotCheck)) {
        /*
        let param = {
          cnfrmnNo: this.focusKeys.cnfrmnNo,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };
        this.$loading('계약번호[' + this.focusKeys.ctrtNo + ']를 회수중 입니다.');
        var response = await axios
          .post('/confdoc/constcmpl/recovery', param)
          .catch(e => console.error('회수를 실패했습니다. ', e));
        this.$loading('close');
        if (response.status == 200) {
          this.$alert('회수가 완료되었습니다.');
          this.onSearch();
        } else {
          this.$alert('회수를 실패하였습니다.');
        }*/
        this.$loading('계약번호[' + this.focusKeys.ctrtNo + ']를 회수중 입니다.');
        var $this = this;
        setTimeout(() => {
          console.log("isCustAlarmNot:",this.isCustAlarmNot);
          let param = {
            cnfrmnNo: this.focusKeys.cnfrmnNo,
            chngPgmId: this.$store.getters['mdi/currentUrls'].url,
            isCustAlarmNot : this.isCustAlarmNot,
          };    
          axios
            .post('/confdoc/constcmpl/recovery', param).then(function(response) {
              $this.$loading('close');
              if (response.status == 200) {
                $this.$alert('회수가 완료되었습니다.');
                $this.onSearch();
              } else {
                $this.$alert('회수를 실패하였습니다.');
              }
            })
            .catch(e => {
              $this.$loading('close');
              console.error('회수를 실패했습니다. ', e)}
            ); 
          
        },2000);
      }
    },
    async onSendAttach() {
      console.log('this.focusKeys', this.focusKeys);

      if (this.focusKeys.cnfrmnSttusCd == '10') {
        this.$alert(
          '계약번호[' +
            this.focusKeys.ctrtNo +
            ']는 작업 중인 상태에서는 작업증빙사진 송부(카톡/메일)을 할 수 없습니다.'
        );
        return;
      }

      if (this.focusKeys.regPhotoYn == 'N') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']는 작업증빙사진을 첨부해야 송부 할 수 있습니다.');
        return;
      }

      if (this.focusKeys.regYn != 'Y') {
        this.$alert('계약번호[' + this.focusKeys.ctrtNo + ']의 고객 확인자 정보를 등록해 주세요.');
        return;
      }

      var message = '';
      message +=
        '해당버튼을 클릭하면 검수자의 전화번호 및 email정보로 작업증빙사진을 다운로드 할 수 있는 링크를 첨부하여 발송합니다.<br/><br/>';
      message += '* 고객이 받지 못하셨다고 하면 버튼을 다시 눌러 재송부 해주시기 바랍니다.';

      if (await this.$confirm(message)) {
        let param = {
          cnfrmnNo: this.focusKeys.cnfrmnNo,
          chngPgmId: this.$store.getters['mdi/currentUrls'].url,
        };

        var response = await axios
          .post('/confdoc/constcmpl/sendAttach', param)
          .catch(e => console.error('작업증빙사진 첨부 송부에 실패했습니다. ', e));
        if (response.status == 200) {
          this.$alert('작업증빙사진 첨부 송부가 완료되었습니다.');
          this.onSearch();
        } else {
          this.$alert('작업증빙사진 첨부 송부에 실패하였습니다.');
        }
      }
    },
    async onViewConfDoc(data) {
      //리포트 정보 조회 후 오즈뷰어 팝업시킴.
      let param = {
        cnfrmnNo: data.cnfrmnNo,
      };

      var opt = {};
      const btns = [];

      if (data.cnfrmnSttusCd == '20') {
        btns.push({
          name: '서명', // 버튼명
          callback: () => {
            // 버튼 Event
            popupInterface[opt.name].vue.callEvent({
              name: 'ConfDocConstCmplViewerPopup_Sign',
              param: data => {
                console.log('ConfDocConstCmplViewerPopup_Sign');
                console.log(data);
                opt.window.close();
                this.$alert('서명이 완료되었습니다.');
                this.onSearch();
              },
            });
          },
        });
      }

      console.log('btns', btns);

      axios
        .post('/ozrpt/selectOzCntrwkFileName', param)
        .then(response => {
          // console.log('조회결과 response >>>', response);
          // console.log('조회결과 response.data >>>', response.data);

          this.OZParam = Object.assign(
            {},
            {
              reportname: response.data.reportPathName,
              odiname: response.data.odiNm,
            }
          );

          this.OZParam.OZFormParam = {
            signable: data.cnfrmnSttusCd == '20' ? 'Y' : 'N', //필수(*):서명가능여부(Y/N)
            signMthCd: '30',
            cnfrmnNo: response.data.cnfrmnNo, //확인서번호
            cntrwkNo: response.data.cntrwkNo, //공사번호
            cntrwkOdr: response.data.cntrwkOdr, //공사차수
            userId: this.otisPage ? this.userInfo.empNo : this.customerPage ? this.userInfo.tradeNo : '',
          };

          opt = $open({
            componentPath: '@/view/confdoc/constcmpl/components/popup/ConfDocConstCmplViewer.vue', // 컴포넌트 경로 (필수)
            param: {
              cnfrmnNo: data.cnfrmnNo,
              OZParam: this.OZParam,
              otisPage: this.otisPage,
            }, // 파라미터 (생략가능)
            btns: btns, // 팝업 버튼 (생략가능)
            name: 'ConfDocConstCmplViewer', // 팝업 윈도우 이름 (생략가능)
            option: 'resizable, scrollbars, width=900, height=650', // 팝업 옵션 (생략가능)
            title: '공사완료확인서조회', // 팝업 제목 (생략가능)
          });
        })
        .catch(function(error) {
          console.log('========error====================');
          console.log(error);
        });
    },
    async onDownloadOfflineConfDoc(data) {
      var fileName = data.confDocFileName;
      var url = '/confdoc/constcmpl/downloadOfflineConfDoc?cnfrmnNo=' + data.cnfrmnNo;
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
      });

      if (response.data.size > 0) {
        const downUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downUrl;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(response.data, fileName);
        } else {
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        this.$alert('오프라인 확인서를 등록해야 다운로드 받을 수 있습니다.');
      }
    },
    async checkeSameListManage(param) {      
      console.log("param____",param);
      //let modalOnoffline = {};
      //modalOnoffline.cnfrmnNo = this.focusKeys.cnfrmnNo;
      //modalOnoffline.cnfrmnSttusCd = this.focusKeys.cnfrmnSttusCd;
      //modalOnoffline.readonly = this.focusKeys.cnfrmnSttusCd == '50' ? true : false;
      //modalOnoffline.onofflineModalId = this.onofflineModalId; //20211208 파일삭제 백그라운드 리로드
     
      this.setModalCheckeSameList(param);
      this.onOpenPopup(this.checkeSameListModalId);
    },
  },
  computed: {
    isCanPrint() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
    isCanOnOffManage() {
      return $isAdmin() ||
        this.isChargeEmp ||
        ((this.tradeType == 'OTIS' || this.tradeType == 'CCPY') && this.cnfrmnMain.cnfrmnSttusCd != '50')
        ? true
        : false;
    },
    isCanReqSign() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
    isCanRecovery() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
    isCanSendAttach() {
      return $isAdmin() || this.isChargeEmp || this.tradeType == 'OTIS' || this.tradeType == 'CCPY' ? true : false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
